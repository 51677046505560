/**************************/
/* GENRAL REUSABLE COMPONENTS */
/**************************/
.container {
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.heading-primary {
  font-weight: 700;
  color: #333;
  letter-spacing: -0.5px;
  font-size: 5.2rem;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}

.section-projects {
  background-color: #f1f3f5;
  padding: 4.8rem 0 9.6rem 0;
  height: 100%;
}

.project-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.5rem; /* 🔄 Más junto todavía */
  padding: 1rem;
  background-color: #f1f3f5;
  border-radius: 0.8rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.project-item {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 400;
  background-color: #495057;
  border-radius: 1.2rem;

  /* width: 40rem; */
  height: 40rem;
  position: relative;

  display: flex;
  flex-direction: column;
  cursor: pointer;

  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  transition: all 0.4s;
}

.project-item--admin {
  /* width: 30rem; */
  flex: 1;
  height: 26rem;
  aspect-ratio: 1/1;
}
.project-item-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-item:hover {
  background-color: #343a40;
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);
}

.project-body {
  margin-top: 75%;
  padding: 2.4rem;
  display: flex;
  align-items: center;
}

.project-body--admin {
  margin-top: 75%;
  height: 25%;
  display: flex;
  align-items: center;
}

.project-item img {
  position: absolute;
  width: 100%;
  height: 75%;
  object-fit: cover;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  background-color: #fff;
}

.project-body-name {
  margin-left: 2.4rem;
  font-size: 2.4rem;
  font-weight: 600;
  justify-content: center;
}

.project-body-name--admin {
  font-size: 1.6rem;
}

.projects-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  margin-bottom: 2rem;
}

.filters-flex {
  display: flex;
  gap: 2.4rem;
}

.projects-nav .icon {
  width: 4.8rem;
  height: 4.8rem;
  color: #333;
  cursor: pointer;
}

/**************************/
/* DROPDOWN */
/**************************/
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  font-size: 2rem;
  font-weight: 350;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.dropdown-content a,
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content a:hover,
.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
