.search-bar {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    background-color: #fff;
    width: 100%;
    width: 730px;
  }

  .dropdown-searcher {
    background: #495057; 
    backdrop-filter: blur(8px); 
    border-radius: 12px;
    padding: 8px;
    /* margin: 16px 0; */
    max-width: 748px;   
    width: 100%;         
    font-family: 'Poppins', sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    
}
.search-bar-input {
  flex: 1;                            /* Ocupa todo el espacio disponible */
  padding: 5px 30px 5px 10px;         /* Padding ajustado según lo solicitado */
  font-size: 14px;                    
  background: rgb(248, 247, 247);     
  color: #030303;                     
  border: 1px solid rgba(255, 255, 255, 0.3);  
  border-radius: 8px;                 
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  box-sizing: border-box;             
}

.search-bar-input:hover {
  border-color: #98C653;             
}
.search-bar-input:focus {
  border-color: #98C653;              
  box-shadow: 0 0 8px rgba(129, 199, 132, 0.5);  
}

  
  .search-bar__button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
  }
  
  .search-bar__icon {
    color: #555;
  }
  
  .search-bar__button:hover .search-bar__icon {
    color: #333;
  }
  