.f-size-14 {
  font-size: 14px;
}

.p-4 {
  padding: 1rem;
}

.min-width-500px {
  min-width: 500px;
}
.flex-1 {
  flex: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}
