.cards-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  
  .create-project-card {
    position: relative;
    border: none;
    border-radius: 16px;
    text-align: center;
    background-color: #3a3f44;
    box-shadow: 0 4px 12px rgba(240, 7, 7, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    flex: 1 1 calc(25% - 20px);
    max-width: calc(25% - 20px);
    min-width: 200px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
  }
  
  .create-project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
 
  /* Icono "+" centrado */
  .icon-container {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    font-size: 32px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.37);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  
  .icon-container:hover {
    background-color: rgba(216, 213, 213, 0.3);

  }
  
  /* Título alineado */
  .create-title {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-top: 30px; 
    text-align: center;
    
  }
  
  /* Descripción con más legibilidad */
  .card-description {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    line-height: 1.4;
  
  }
  .required {
    color: red;
  }
  .error-message {
    color:red;
    margin: 0.5rem;
  }
  
  /* Responsivo */
  @media (max-width: 1024px) {
    .create-project-card {
      flex: 1 1 calc(33.33% - 20px);
      max-width: calc(33.33% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .create-project-card {
      flex: 1 1 calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .create-project-card {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  