.project-card-stats {
  position: absolute;
  top: 10px;
  height: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 20px);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: var(--box-shadow-dark);
}

.project-stat-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.project-stat-icon-container {
  padding: 0.5rem;
  border-radius: 50%;
  background: #495057;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-stat-tooltip {
  max-width: 500px;
  z-index: 9;
  font-size: 0.8rem;
  background-color: #495057;
}



.project-body--admin p {
  display: inline-block;       /* Para que text-overflow funcione */
  white-space: nowrap;         /* Evita el salto de línea */
  overflow: hidden;            /* Oculta el texto que sobrepase el ancho */
  text-overflow: ellipsis;  
}



.project-body-name--admin p {
  max-width: 130px;            /* Ajusta este ancho a tu diseño */
}

.project-body-icons p{
  max-width: 60px; 
}


/* Asegúrate de que el contenedor del tooltip tenga position relative */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 160px; /* ejemplo */
  left: 50%;
  transform: translateX(-50%);
  
  display: block; /* o inline-block */
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  white-space: nowrap; /* para que no haga salto de línea */
  
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  font-size: 1.2rem;
}

.tooltip:hover::after {
  opacity: 1;

}

