/* DropdownSearch.css */

.dropdown-search {
    background: #495057; 
    backdrop-filter: blur(8px); 
    border-radius: 12px;
    padding: 8px;
    margin: 16px 0;
    max-width: 320px;
    font-family: 'Poppins', sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.2);
    
  }
  
  .dropdown-search__label {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8px;
    display: block;
    font-size: 11px;
  }
  
  .dropdown-search__select {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    background: rgb(248, 247, 247); 
    color: #030303;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    outline: none;
  }
  
  .dropdown-search__select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg fill='%23000000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 9px;
    background-size: 16px;
    font-size: 1.2rem; 
    padding: 12px;
  }
  
  .dropdown-search__select:hover {
    border-color: #98C653;
  }
  .dropdown-search:hover {
    display: block;
  }
  
  .dropdown-search__select:focus {
    border-color: #98C653;
    box-shadow: 0 0 8px rgba(129, 199, 132, 0.5);
  }
  
  .dropdown-div{
    
  }