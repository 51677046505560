.form-main-info-proyecto-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  flex: 1;
}

.input-calendar-container {
  position: relative;
}


.calendar-input {
  cursor: pointer;
}

.calendars-inputs-container {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}
.label-class{
  font-size: 0.875rem;
  color: #494949; 
  font-size: 13px;
}

.calendar-input-container2 {
  flex: 1;
}

/*Edit Hub y Id*/



.calendar-input-container {
  flex: 1;
}

.error-form {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.input-wrapper {
  position: relative;
}

.adornment-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.warning-tipos-button-icon {
  position: absolute;
  right: -25px;
  margin-top: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff9800;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: blink 1s infinite; /* Parpadeo */
}

.warning-tipos-button--hub-id{
  margin-top: 13px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.warning-tipos-button--item-id {
  top: 57%;
}

.warning-tipos-icon {
  color: white;
  font-size: 14px;
  margin: 0;
}

.badge-tooltip {
  font-size: 9px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.button-text {
  font-size: 1.2rem;
}


.Advertencia {
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
}

.pencil{
  cursor: pointer;
}

