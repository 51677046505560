.delete-bimlink-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 400px;
    overflow-y: auto;
}

.delete-bimlink-subtitle {
    font-weight: 500;
}

.delete-bimlink-tipos-list {
    margin-left: 4rem;
}