/**************************/
/* CONTENEDOR PRINCIPAL */
/**************************/
.proyectos-list-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);  /* 4 columnas fijas */
    gap: 1.5rem;  /* Espacio uniforme entre tarjetas */
    padding: 2rem;
    background-color: #f1f3f5;
    border-radius: 0.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;  /* Centrar el contenedor */
  }
  
  /**************************/
  /* TARJETAS DE PROYECTOS */
  /**************************/
  .project-card, .create-project-button {
    background-color: #495057;
    color: #fff;
    border-radius: 0.8rem;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 250px;
    max-height: 250px;
  }
  
  .project-card:hover, .create-project-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  
  /**************************/
  /* RESPONSIVIDAD */
  /**************************/
  @media (max-width: 1024px) {
    .proyectos-list-container {
      grid-template-columns: repeat(2, 1fr);  
    }
  }
  
  @media (max-width: 768px) {
    .proyectos-list-container {
      grid-template-columns: repeat(1, 1fr);  
    }
  
    .project-card, .create-project-button {
      min-height: 200px;
      max-height: 200px;
    }
  }
  